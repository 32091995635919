<template>
  <div class="container job-panel">
    <img class="decoration-bg-2" src="@/assets/bg1.png" alt="" />
    <img class="decoration-bg-3" src="@/assets/bg2.png" alt="" />

    <img class="job-header-image" src="@/assets/job-list.png" alt="" />

    <div class="company-switch">
      <div
        @click="changeCategory('')"
        :class="activeCategory === '' ? 'active' : 'gray'"
      >
        {{ L("全部") }}
      </div>
      <div
        @click="changeCategory('实习')"
        :class="activeCategory === '实习' ? 'active' : 'gray'"
      >
        {{ L("实习") }}
      </div>
      <div
        @click="changeCategory('长期实习')"
        :class="activeCategory === '长期实习' ? 'active' : 'gray'"
      >
        {{ L("长期实习") }}
      </div>
      <div
        @click="changeCategory('实习转就业')"
        :class="activeCategory === '实习转就业' ? 'active' : 'gray'"
      >
        {{ L("实习转就业") }}
      </div>
      <div
        @click="changeCategory('就业')"
        :class="activeCategory === '就业' ? 'active' : 'gray'"
      >
        {{ L("就业") }}
      </div>
    </div>

    <div class="job-list">
      <div
        class="job-item"
        v-for="it in renderList"
        :key="it.id"
        @click="route(it)"
      >
        <div class="job-header">
          <div class="job-title">{{ L(it.name) }}</div>
          <div class="job-salary">{{ L("招聘") }} {{ it.plan_num }} 人</div>
        </div>
        <div class="job-education" style="font-size: 13px; margin-bottom: 20px">
          {{ L(it.job_detail) }}
        </div>
        <div class="job-tags">
          <div :title="L('类型')">
            <i class="ri-shield-user-line ri-fw"></i> {{ L(it.type) }}
          </div>
          <div :title="L('专业')">
            <i class="ri-book-mark-line ri-fw"></i> {{ L(it.major || "不限") }}
          </div>
          <div v-if="it.salary > 0" :title="L('实习补贴')">
            <i class="ri-money-cny-circle-line ri-fw"></i> {{ L(it.salary) }}/月
          </div>
          <div v-else :title="L('薪资')">
            <i class="ri-money-cny-circle-line ri-fw"></i>
            {{ L(it.salary_detail || "-") }}
          </div>
        </div>
        <div class="user">
          <!-- <img v-if="it.company_logo_img" :src="it.company_logo_img" class="logo" alt="" />
          <div v-else class="logo" style="font-size: 13px; padding-top: 5px">
            <span v-text="L(it.company_short_name)"></span>
          </div> -->
          <div style="flex: 1">
            <div class="user-title" v-text="L(it.company_name)"></div>
            <div class="user-description">
              <span class="gray"
                >{{ L(it.company_type) }} | {{ L(it.company_city) }} |
                {{ L(it.company_area) }}</span
              >
              <span
                >{{ L("共") }} {{ it.company_job_num }} {{ L("岗位") }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="banner" @click="open(ad['0'])">
        <img :src="ad['0'] ? ad['0'].face_file : ''" alt="" />
      </div>

      <div
        class="job-item"
        v-for="it in renderList2"
        :key="it.id"
        @click="route(it)"
      >
        <div class="job-header">
          <div class="job-title">{{ L(it.name) }}</div>
          <div class="job-salary">{{ L("招聘") }} {{ it.plan_num }} 人</div>
        </div>
        <div class="job-education" style="font-size: 13px; margin-bottom: 20px">
          {{ L(it.job_detail) }}
        </div>
        <div class="job-tags">
          <div :title="L('类型')">
            <i class="ri-shield-user-line ri-fw"></i> {{ L(it.type) }}
          </div>
          <div :title="L('专业')">
            <i class="ri-book-mark-line ri-fw"></i> {{ L(it.major || "不限") }}
          </div>
          <div v-if="it.salary > 0" :title="L('实习补贴')">
            <i class="ri-money-cny-circle-line ri-fw"></i> {{ L(it.salary) }}/月
          </div>
          <div v-else :title="L('薪资')">
            <i class="ri-money-cny-circle-line ri-fw"></i>
            {{ L(it.salary_detail || "-") }}
          </div>
        </div>
        <div class="user">
          <!-- <img v-if="it.company_logo_img" :src="it.company_logo_img" class="logo" alt="" /> -->
          <!-- <div v-else class="logo" style="font-size: 13px; padding-top: 5px">
            <span v-text="L(it.company_short_name)"></span>
          </div> -->
          <div style="flex: 1">
            <div class="user-title" v-text="L(it.company_name)"></div>
            <div class="user-description">
              <span class="gray"
                >{{ L(it.company_type) }} | {{ L(it.company_city) }} |
                {{ L(it.company_area) }}</span
              >
              <span
                >{{ L("共") }} {{ it.company_job_num }} {{ L("岗位") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="more-company" @click="loadMore">
      {{ L("更多职位") }}
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/job";
import { mapState } from "vuex";

import { fetchList as fetchAd } from "@/api/ad";

export default {
  data() {
    return {
      current: 1,
      pageSize: 11,
      list: [],

      activeCategory: "",

      ad: {},
    };
  },

  watch: {
    selected() {
      this.getList();
    },
  },

  computed: {
    ...mapState("major", ["selected"]),

    filteredList() {
      if (this.selected.length > 0) {
        const hasSelectedList = [];
        const notSelectedList = [];
        this.list.forEach((item) => {
          const index = this.selected.indexOf(item.major);
          if (index > -1) {
            hasSelectedList.push(item);
          } else {
            notSelectedList.push(item);
          }
        });
        return [...hasSelectedList, ...notSelectedList];
      } else {
        return this.list;
      }
    },

    renderList() {
      return this.filteredList.filter((item, index) => index < 8);
    },
    renderList2() {
      return this.filteredList.filter((item, index) => index >= 8);
    },
  },

  mounted() {
    this.getList();

    fetchAd({
      code: "HOME_JOB_BANNER",
    }).then((res) => {
      console.log("banner is ", res);
      if (res && res.list && res.list.length > 0) {
        this.ad = res.list;
      }
    });
  },

  methods: {
    open(item) {
      window.open(item.url);
    },
    getList() {
      const { current, pageSize, activeCategory } = this;

      this.loading = true;
      fetchList({
        page: current,
        rows: pageSize,
        type: activeCategory,
        major:
          this.selected.length > 0
            ? this.L2(this.selected.join(","))
            : undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            console.log("res.list", res.list);
            const result = res.list.sort((x, y) => {
              const top1 = parseInt(x.top);
              const top2 = parseInt(y.top);
              if (top1 > top2) {
                return 1;
              } else if (top1 < top2) {
                return -1;
              } else {
                return 0;
              }
            });
            console.log("result", result);
            this.list = Object.freeze(result);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    loadMore() {
      this.$router.push("/search");
    },

    route(item) {
      this.$router.push("/job-detail?uuid=" + item.uuid);
    },

    changeCategory(value) {
      this.activeCategory = value;
      this.getList();
    },
  },
};
</script>


<style lang="less" scoped>
.job-panel {
  position: relative;
  overflow: hidden;
}

.decoration-bg-2 {
  position: absolute;
  top: 60px;
  left: -180px;
  width: 72px;
}

.decoration-bg-3 {
  position: absolute;
  top: 300px;
  right: -155px;
  width: 67px;
}

.job-header-image {
  display: block;
  margin: 35px auto 27px;
  width: 176px;
}

.job-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.job-item {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 16px;
  transition: all 0.3s;
  cursor: pointer;
  max-width: 385px;
}

.job-item:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.job-item .job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.job-item .job-title {
  font-size: 22px;
  color: #004BB0;
  width: 84%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.job-item .job-salary {
  flex-shrink: 0;
  font-size: 15px;
  color: #fabb1c;
}

.job-item .job-education {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  height: 60px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.job-item .job-tags {
  padding-bottom: 4px;
  margin: 12px 0;
  display: flex;
  flex-wrap: wrap;
}

.job-item .job-tags > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  height: 25px;
  padding: 0 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-right: 8px;
  background: rgba(32, 49, 127, 0.05);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.job-item .user {
  display: flex;
  align-items: center;
}

.job-item .user .logo {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  background-color: #004BB0;
  color: #fff;
  overflow: hidden;
}

.job-item .user .user-title {
  font-size: 16px;
  color: #000000;
  margin-bottom: 4px;
}

.job-item .user .user-description {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: rgba(0, 0, 0, 0.4);
  color: #999;
}

.more-company {
  margin: 40px auto;
  width: 385px;
  height: 53px;
  border: 2px solid #20317f;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #20317f;
  cursor: pointer;
}

.more-company:hover {
  background-color: #20317f;
  color: #fff;
}

.banner {
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    cursor: pointer;

    img {
      display: block;
      width: 90%;
      height: auto;
      object-fit: cover;
    }
  }

  .job-list {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 40px;
  }

  .job-item {
    width: 90%;
  }

  .job-item .job-education {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    height: auto;
  }

  .job-item .job-tags > div {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 60px;
    height: 25px;
    padding: 0 8px;
    box-sizing: border-box;

    margin-bottom: 8px;
    margin-right: 4px;
    background: rgba(32, 49, 127, 0.05);
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .job-item .job-title {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .job-item .job-salary {
    flex-shrink: 0;
  }

  .more-company {
    margin: 24px auto 66px;
    width: 80vw;
    height: 40px;
    font-size: 18px;
  }

  .company-switch > div {
    margin-right: 12px !important;
  }
}

.company-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}

.company-switch > div {
  font-size: 16px;
}

.company-switch > div {
  margin-right: 65px;
  cursor: pointer;
}

.company-switch > div:last-child {
  margin-right: 0;
}

.company-switch .active {
  color: #20317f;
  position: relative;
}

.company-switch .active::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  height: 0;
  width: 0;
  transform: translateX(-50%);
  border: 10px solid #20317f;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
</style>